
/* ----- global variables ------ */

$max-page-width: 1440px; 


/* ----- global stuff ------ */

h1, h2, h3, h4, h5, h6 {
  color: $black;
  text-transform: uppercase;
  padding: 0;
  padding-left: rem-calc(15);
  margin: 0;
  margin-bottom: rem-calc(10);
  margin-top: rem-calc(25);
  border-left: 4px solid $secondary-color;
}

h1.inline, h2.inline, h3.inline, h4.inline, h5.inline, h6.inline {
  border: 0;
  margin: 0;
  padding: 0;
}

.row {
  padding: 0 5px;

  @media(min-width:768px){
    padding: 0 50px;
  }
  max-width: $max-page-width; /* globally increase row width */
}
dl dt {
  margin: 0;
  padding: 0.2rem 0;
}
dl dd {
  margin: 0;
  padding: 0 0.5rem;
}

.pl10 { padding-left: 10px !important; }
.pl20 { padding-left: 20px !important; }

.pr50 { padding-right: 50px !important; }

.bg-white {
  background-color: $white !important;
}

/* ----- header stuff ------ */

.quote {
  margin: auto;
  padding: 0px 20px;
  height: auto;
  background-color: $secondary-color;
}

.quote ul.left  {
  padding: 10px 5px;

   @media(max-width:768px) {
    margin: 0;
    padding: 0;
  }

}
.quote cite {
  padding: 20px 10px 5px 10px;
  color: $black;
  font-family: Volkhov;
  font-size: 14px;
  font-weight: 100;
  font-style: italic;

  @media(max-width:768px) {
    font-size: 12px;
  }
}

.quote .social-icons ul {
  background-color: $secondary-color;
}
.quote .social-icons li a {
  font-size: rem-calc(18);
  display: block;
  width: 34px;
  border-radius: 50%;
  text-align: center;
  background: $secondary-color !important;
  color: $white;
}

.quote .social-icons li a:hover {
  color: $secondary-color;
  background: $white;
}


.top-bar .name h1 a, .top-bar .name h2 a, .top-bar .name h3 a, .top-bar .name h4 a,
.top-bar .name p a, .top-bar .name span a {
  font-weight: normal;
}
.top-bar .name {
  padding: 0px 25px;
}



/* ----- navigation stuff ----- */

span.brand {
  color: $secondary-color;
}

$dropdownmenu-arrow-color: $primary-color;

#navigation {
  margin: 50px 0px;
  width: 95%;
  padding-bottom: 25px;
}
#navigation .top-bar-section ul li a {
  font-size: $font-size-h4;
  font-family: $header-font-family;
}
#navigation .top-bar-section ul.dropdown li a {
  font-size: $font-size-small;
}


/* ----- frontpage stuff ----- */

h1.frontpage-header {
  font-size: $font-size-h3;
  padding: 10px;
  border-top: 6px solid $secondary-color;
}
.frontpage-featured {
  padding: 20px 10px;

  img {
    max-height: 220px;
  }

}


.frontpage-widget {
  padding: 10px 10px;
}

/*-- frontpage slider --*/

.frontpage-slider-container {
  margin: 5px auto;
  position: relative;
  width: 95%;
  max-width: $max-page-width;
}

.frontpage-slider-image {
  overflow: hidden;
  width: 100%;
  min-height: 250px;
  max-height: 450px;
  transition: all .6s
}

.frontpage-slider-link {
  margin-bottom: 20px;
  display: block
}

.frontpage-slider-slide:hover .frontpage-slider-image {
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -moz-transform: scale(1.1) rotate(.1deg);
  opacity: .9;
}

.frontpage-slider-slide {
  overflow: hidden;
  display: block;
  position: relative;
}

.slide-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  font-family: "Fjalla One", Arial, sans-serif;
  color: $white;
  width: 100%;
  background: linear-gradient(to bottom, rgba(25, 35, 45, 0) 0, #19232d 100%);
  text-shadow: 0 1px 1px #000;
}
.slide-content h2 {
  color: $white;
}

.slide-content a {
  color: $secondary-color;
}

.slide-content a:hover {
  color: $secondary-color;
  text-decoration: underline;
}

.frontpage-slider-container .slick-next, .frontpage-slider-container .slick-prev {
  height: 47px;
  width: 50px;
//  color: $white;
  color: $secondary-color;
//  background-color: $white;
  opacity: 0;
  z-index: 200;
  transition: opacity .6s;
}

.frontpage-slider-container .slick-next:before, .frontpage-slider-container .slick-prev:before {
  height: 47px;
  width: 50px;
//  color: $white;
  color: $secondary-color;
//  background-color: $white;
  font-size: 50px;
  line-height: 55px;
  display: block;
}

.frontpage-slider-container .slick-next:hover, .frontpage-slider-container .slick-prev:hover {
  opacity: 1;
}

.frontpage-slider-container .slick-next {
  right: 25px;
}

.frontpage-slider-container .slick-prev {
  left: 25px;
}

.frontpage-slider-container:hover .slick-next, .frontpage-slider-container:hover .slick-prev {
  opacity: 1;
}

.frontpage-slider-container .slick-dots {
  display: -ms-flexbox;
  display: flex;
  bottom: 0;
  position: absolute;
}

.frontpage-slider-container .slick-dots li {
  -ms-flex: 1 1;
  flex: 1 1;
  height: auto;
  text-align: center;
}

.frontpage-slider-container .slick-dots li button {
  display: inline-block;
  background-color: $grey-10;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  padding: 0;
  transition: all .6s;
}

.frontpage-slider-container .slick-dots li button:hover {
  opacity: .6;
}

.frontpage-slider-container .slick-dots li button:before {
  display: none;
}

.frontpage-slider-container .slick-dots li.slick-active button {
  background-color: $secondary-color;
}

.frontpage-slider-container .slick-dots:hover li button {
  height: 8px;
}

div.ecosystem {

  div.row {
    margin: 0 auto;
    width: 95%;
    max-width: $max-page-width;
    padding: 25px;
    background-color: $grey-2;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: center;
    text-shadow: 0 1px 1px $white;
  }

  div.columns {
    padding: 0 40px;
  }

  div.featured {
    padding: 20px 40px;
    background: $grey-1;
    border-radius: 5px;
  }

  ul.side-list {
    margin: 0;
    padding: 0;
  }
  .side-list li {
    list-style-type: none;
    margin-top: -1px;
    padding: 1em 0.8em;
    border-top: 1px solid $grey-4;
    border-bottom: 1px solid $grey-4;
  }
  .side-list li:hover, .side-list li:focus {
    background: $grey-1;
  }
  .side-list li h6 {
    display: inline;
  }
  .side-list li h6 a {
    display: inline;
    padding-left: 0.6em;
  }
  
}


/* ----- page header stuff (masthead) ----- */

.backstretch img {
  position: static !important;
}


/* ----- sidebar stuff ----- */

div.custom-panel {
  @include panel(
    // Sets the panel background color. Default: $panel-pg
    $bg: $grey-1,
    // Sets the panel padding amount. Default: $panel-padding
    $padding:20px,
    // Sets the font color based on the darkness of the bg & resets header line-heights for panels. Default: $panel-header-adjust
    $adjust:true
  );

  h4 {
    padding: 0.2rem 0;
    padding-left: 15px;
    margin: 0;
  }
  h6 {
    margin: 0.3rem 0;
  }
  hr {
    margin: 0.6rem -0.6rem;
  }
  dl {
    font-size: 90%;
  }
  dt {
    margin: 0;
    padding: 0.2rem 0;
  }
  dd {
    margin: 0;
    padding: 0 0.5rem;
  }

}

hr.brand {
  border: 1px solid $secondary-color;
}

div.map {
  position: relative;
  height: 0;
  overflow: hidden;
}

/* 16x9 Aspect Ratio */
div.map-16x9 {
  padding-bottom: 56.25%;
}

/* 4x3 Aspect Ratio */
div.map-4x3 {
  padding-bottom: 75%;
}

div.map iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ----- main content stuff ----- */

p a, article a {
  border-bottom: 1px dotted $secondary-color;
}

p a:hover, article a:hover {
  border-bottom: 2px solid $secondary-color;
}

.subheadline {
  font-style: italic;
}

/* ecosystem  */

article.ecosystem {

  header {
    margin: 10px 25px;
  }

  section {
    padding: 25px 5px;
    margin: 0 5px;
    background-color: $grey-2;

    div.contact {
      padding: 25px 10px;
      background-color: $grey-4;
    }
    div.services {
    }
  }

  div.related {
  }
}


/* events */

article.eventList span.year {
  display: block;
  position: absolute;
  top: 86px;
  font-size: 2.2em;
  color: $secondary-color;
  opacity: 0.6;
}
article.eventList div.eventDate {
  margin: 0px 25px 15px 0px;
  display: inline-block;
  width: 55px;
  vertical-align: top;
  overflow: hidden;
  border: 1px solid $grey-5;
  border-radius: 4px;
  color: $black;
  text-align: center;
  font-weight: 700;
  line-height: 1em;
}
div.eventDate span.month {
  display: block;
  width: 100%;
  font-size: .9em;
  text-transform: uppercase;
  background-color: $secondary-color;
}
div.eventDate span.day {
  font-size: 2em;
  line-height: 1.1em;
}
div.eventDate span.weekDay {
  font-size: .8em;
}

article.eventList div.eventContent {
  padding-right: 15px;
  padding-left: 65px;
}


/* ----- meta information ----- */

#page-meta, #page-meta a {
  color: $grey-5;
  border: 0;
}

#page-meta .button {
  background: $grey-1;
  color: $grey-5;
  border: 0;
}
#page-meta .button.active {
  background: $black;
  color: $white;
  border: 0;
}
#page-meta .button:hover {
  background: $secondary-color;
  border: 0;
  color: $white;
}


#share .button {
  background: $grey-1;
  color: $white;
  border: 0;
}
#share .button:hover {
  background: $white;
  color: $black;
  border: 1px solid $black;
}

#share .button.tw {
  background: #2aa9e0; //twitter blue
}
#share .button.tw:hover {
  background: $white;
  color: #2aa9e0; //twitter blue
}
#share .button.ln {
  background: #02669a; //linkedin blue
}
#share .button.ln:hover {
  background: $white;
  color: #02669a; //linkedin blue
}
#share .button.fb {
  background: #204385; //facebook blue
}
#share .button.fb:hover {
  background: $white;
  color: #204385; //facebook blue
}
#share .button.gp {
  background: #d3492c; //gplus orange
}
#share .button.gp:hover {
  background: $white;
  color: #d3492c; //gplus orange
}
#share .button.pi {
  background: #ca2128 //pinterest red
}
#share .button.pi:hover {
  background: $white;
  color: #ca2128; //pinterest red
}
#share .button.tb {
  background: #44546b; //tumblr gray
}
#share .button.tb:hover {
  background: $white;
  color: #44546b; //tumblr gray
}


#post-nav {
  margin-top: 30px;
}
#post-nav .button {
  background: $white;
  border: 1px solid $grey-8;
  color: $black;
}
#post-nav .button:hover {
  background: $secondary-color;
  border: 0;
  color: $white;
}


/* ----- footer stuff ----- */

div.bottom-bar {
  background-color: $black;
}

#subfooter {
  font-family: $header-font-family;
}


