@charset "utf-8";

@import "functions.scss";
@import "01_settings_colors.scss";
@import "02_settings_typography.scss";
@import "03_settings_mixins_media_queries.scss";
@import "04_settings_global.scss";
@import "05_normalize.scss"; // normalize.css v3.0.2

@import "foundation-components/top-bar";
@import "foundation-components/accordion";
@import "foundation-components/alert-boxes";
@import "foundation-components/breadcrumbs";
@import "foundation-components/block-grid";
@import "foundation-components/button-groups";
@import "foundation-components/buttons";
@import "foundation-components/clearing";
@import "foundation-components/dropdown";
@import "foundation-components/dropdown-buttons";
@import "foundation-components/flex-video";
@import "foundation-components/forms";
@import "foundation-components/grid";
@import "foundation-components/inline-lists";
@import "foundation-components/keystrokes";
@import "foundation-components/panels";
@import "foundation-components/reveal";

@import "foundation-components/sub-nav";
@import "foundation-components/tables";
@import "foundation-components/thumbs";
@import "foundation-components/top-bar";
@import "foundation-components/type";
@import "foundation-components/visibility";
@import "foundation-components/tabs";
@import "foundation-components/pagination";

@import "06_typography.scss";
@import "07_layout.scss";
@import "foundation-components/grid";
@import "09_elements.scss";

@import "11_syntax-highlighting.scss";

@import "fontawesome/fontawesome.scss";
@import "fontawesome/fa-solid.scss";
@import "fontawesome/fa-brands.scss";

@import "custom.scss";

